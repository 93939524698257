<template>
  <v-container fluid class="d-flex fill-height flex-column justify-center align-center">
    <p class="title-po">404</p>
    <p class="subtitle-po">{{ $t('app.404.title') }}</p>
    <v-btn class="subtitle" text @click="$router.push('/')">{{ $t('app.404.back') }}</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "NotFoundNotConnected",
  beforeMount() {
    this.changeLang(navigator.language.split("-")[0] || 'fr')
  }
}
</script>

<style scoped>

</style>